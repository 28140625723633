const SEARCH_KEYWORDS_SA_NAME = 'click_top_site_search,click_icon_search,click_list_top_navigation'
const CLICK_GOODS_SA_NAME = 'click_goods_list,click_module_goods_list'
const PAGE_VIEW_SA_NAME = 'page_view'
const CLICK_ADD_BAG_SA_NAME = 'click_add_bag'
const CLICK_ADD_COLLECT_SA_NAME = 'click_add_collect'
const CLICK_CHECKOUT_SA_NAME = 'click_checkout'

const handleFromPage4SearchKeywords = (data, collectData) => {
  const isClickTopNav = data?.activity_name === 'click_list_top_navigation'
  const hitSearchKeyWords = isClickTopNav 
    ? data?.activity_param?.top_navigation_query // 转query的图文，才命中这个逻辑
    : SEARCH_KEYWORDS_SA_NAME.split(',').includes(data?.activity_name)
  
  if (!hitSearchKeyWords) return

  const cycleId = Object.keys(collectData)[0]
  const clickTopSiteSearch = collectData?.[cycleId]?.[SEARCH_KEYWORDS_SA_NAME]
  const pvArr = collectData?.[cycleId]?.[PAGE_VIEW_SA_NAME] || []
  const startCyclePageName = collectData?.[cycleId]?._fromPv?.page_name

  // cycle开始的时候
  if (!clickTopSiteSearch?.length) {
    data.processedEntryFrom = startCyclePageName
    return data
  }

  // 在一个cycle内
  const exclueLeavePvArr = pvArr.filter(item => !item.end_time) // 过滤掉离开的pv
  let entryFromIndex = exclueLeavePvArr.length - 2
  // 不是在预搜页点击的搜索按钮（目前在商详和列表 可以直接点击搜索框的搜索按钮）
  if (data.page_name !== 'page_pre_search') {
    entryFromIndex = exclueLeavePvArr.length - 1
  }
  data.processedEntryFrom = exclueLeavePvArr[entryFromIndex].page_name

  return data
}

const handleSearchKeywords = (collectData, cycleId) => {
  const clickTopSiteSearchInfo = collectData?.[cycleId]?.[SEARCH_KEYWORDS_SA_NAME]
  if (clickTopSiteSearchInfo?.length) {
    const cycleKeywordsInfo = clickTopSiteSearchInfo.reduce(
      (acc, cur) => {
        // 点击图文转query
        if (cur.activity_name === 'click_list_top_navigation') {
          if (cur.activity_param?.top_navigation_query) {
            const topNavigationQuery = cur?.activity_param?.top_navigation_query.split('`')
            const searchedKeywords = cur?.page_param?.pagefrom?.split('`')?.[2]?.split('>').join(' ').replace(',', ' ')
            const topNavigationWords = topNavigationQuery[topNavigationQuery?.length - 2]
            // 倒序（新的行为放前面）
            acc.keywords.unshift(`${searchedKeywords} ${topNavigationWords}`)
            acc.timeStamp.unshift(cur?.timestamp)
            acc.entryFrom.unshift(cur?.processedEntryFrom)
          }
          return acc
        }

        // 倒序（新的行为放前面）
        acc.keywords.unshift(cur?.activity_param?.search_content.replace(',', ' '))
        acc.timeStamp.unshift(cur?.timestamp)
        acc.entryFrom.unshift(cur?.processedEntryFrom)

        return acc
      },
      { keywords: [], timeStamp: [], entryFrom: [] }
    )

    return cycleKeywordsInfo
  }

  return { keywords: [], timeStamp: [], entryFrom: [] }
}

const handleClickGoods = (collectData, cycleId) => {
  const clickGoodsListInfo = collectData?.[cycleId]?.[CLICK_GOODS_SA_NAME]
  if (clickGoodsListInfo?.length) {
    const cycleClickGoodsInfo = clickGoodsListInfo.reduce(
      (acc, cur) => {
        const isClickToDetail = cur?.activity_param?.style === 'detail' // 点击跳转商详

        if (!isClickToDetail) return acc

        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(cur?.activity_param?.goods_list?.split('`')?.[0])
        acc.timeStamp.unshift(cur?.timestamp)
        acc.pageName.unshift(cur?.page_name)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleClickGoodsInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleClickAddBag = (collectData, cycleId) => {
  const clickAddBagInfo = collectData?.[cycleId]?.[CLICK_ADD_BAG_SA_NAME]
  if (clickAddBagInfo?.length) {
    const cycleCartInfo = clickAddBagInfo.reduce(
      (acc, cur) => {
        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(cur?.activity_param?.goods_id)
        acc.timeStamp.unshift(cur?.timestamp)
        acc.pageName.unshift(cur?.page_name)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleCartInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleClickAddCollect = (collectData, cycleId) => {
  const clickAddCollectInfo = collectData?.[cycleId]?.[CLICK_ADD_COLLECT_SA_NAME]
  if (clickAddCollectInfo?.length) {
    const cycleFavInfo = clickAddCollectInfo.reduce(
      (acc, cur) => {
        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(cur?.activity_param?.goods_id)
        acc.timeStamp.unshift(cur?.timestamp)
        acc.pageName.unshift(cur?.page_name)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleFavInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const handleClickCollect = (collectData, cycleId) => {
  const clickCheckoutInfo = collectData?.[cycleId]?.[CLICK_CHECKOUT_SA_NAME]
  if (clickCheckoutInfo?.length) {
    const cycleCheckoutInfo = clickCheckoutInfo.reduce(
      (acc, cur1) => {
        const goodsListArr =
          cur1?.activity_param?.goods_list.split(',') || []

        const eachCheckoutInfo = goodsListArr.reduce(
          (acc, cur2) => {
            acc.goodsIds.push(cur2.split('`')[0])
            acc.timeStamp.push(cur1?.timestamp)
            acc.pageName.push(cur1?.page_name)

            return acc
          },
          { goodsIds: [], timeStamp: [], pageName: [] }
        )

        // 倒序（新的行为放前面）
        acc.goodsIds.unshift(...eachCheckoutInfo.goodsIds)
        acc.timeStamp.unshift(...eachCheckoutInfo.timeStamp)
        acc.pageName.unshift(...eachCheckoutInfo.pageName)

        // 最多保留30条数据
        acc.goodsIds.splice(30)
        acc.timeStamp.splice(30)
        acc.pageName.splice(30)

        return acc
      },
      { goodsIds: [], timeStamp: [], pageName: [] }
    )

    return cycleCheckoutInfo
  }

  return { goodsIds: [], timeStamp: [], pageName: [] }
}

const config = {
  featureKey: 'search_cycle', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  saNames: [
    {
      saName: PAGE_VIEW_SA_NAME,
      limit: 5
    },
    {
      saName: SEARCH_KEYWORDS_SA_NAME,
      limit: 5
    },
    {
      saName: CLICK_GOODS_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_ADD_BAG_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_ADD_COLLECT_SA_NAME,
      limit: 30
    },
    {
      saName: CLICK_CHECKOUT_SA_NAME,
      limit: 30
    },
  ], // 所经过的埋点name
  targetNames: [
    {
      name: 'pre_search',
      url: '/presearch'
    },
    {
      name: 'product-list-v2',
      url: '/pdsearch/.+'
    }
  ], // 从那些页面开始记录
  clearDataNames: [
    {
      name: 'config_index',
      url: '/'
    }
  ],
  handleAction(data, collectData) { // 过滤数据, 每次埋点都会触发
    // 处理搜索词的来源页面
    const processedDataFromSearchkeywords = handleFromPage4SearchKeywords(data, collectData)
    if (processedDataFromSearchkeywords) return processedDataFromSearchkeywords

    return data
  },
  onTrigger({ collectData, routerAction }) {
    const cycleId = Object.keys(collectData)[0] // 时间戳 作为 cycleId
    const cycleKeywordsInfo = handleSearchKeywords(collectData, cycleId) // 搜索词
    const cycleClickGoodsInfo = handleClickGoods(collectData, cycleId) // 点击商品跳转商详
    const cycleCartInfo = handleClickAddBag(collectData, cycleId) // 加车
    const cycleFavInfo = handleClickAddCollect(collectData, cycleId) // 收藏
    const cycleCheckoutInfo = handleClickCollect(collectData, cycleId) // 结算

    return {
      search_cycle: {
        rt_f_search_cycle_id: cycleId, // cycle开始的时间戳

        rt_f_search_cycle_keywords: cycleKeywordsInfo.keywords.join(),
        rt_f_search_cycle_keywords_ts: cycleKeywordsInfo.timeStamp.join(),
        rt_f_search_cycle_keyword_entry_from: cycleKeywordsInfo.entryFrom.join(),

        rt_f_search_cycle_click: cycleClickGoodsInfo.goodsIds.join(),
        rt_f_search_cycle_click_ts: cycleClickGoodsInfo.timeStamp.join(),
        rt_f_search_cycle_click_pn: cycleClickGoodsInfo.pageName.join(),

        rt_f_search_cycle_cart: cycleCartInfo.goodsIds.join(),
        rt_f_search_cycle_cart_ts: cycleCartInfo.timeStamp.join(),
        rt_f_search_cycle_cart_pn: cycleCartInfo.pageName.join(),

        rt_f_search_cycle_fav: cycleFavInfo.goodsIds.join(),
        rt_f_search_cycle_fav_ts: cycleFavInfo.timeStamp.join(),
        rt_f_search_cycle_fav_pn: cycleFavInfo.pageName.join(),

        rt_f_search_cycle_checkout: cycleCheckoutInfo.goodsIds.join(),
        rt_f_search_cycle_checkout_ts: cycleCheckoutInfo.timeStamp.join(),
        rt_f_search_cycle_checkout_pn: cycleCheckoutInfo.pageName.join()
      }
    }
  }
}

export default config
