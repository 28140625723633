import { LIST_PAGE_PATHS } from 'public/src/pages/product_app/router/constants.js'

const _getLastItemByList = (list = []) => {
  return list?.length ? list[list.length - 1] : undefined
}

const _getSessionItemByList = (list = [], part = ',') => {
  return list?.length ? list.join(part) : undefined
}

const config = {
  featureKey: 'detailsRecommend',  // 特征key
  saNames: ['click_filter', 'click_list_top_navigation', 'click_sort', 'expose_goods_list', 'click_category', 'click_goods_list_label', 'page_view'],                // 所经过的埋点name
  targetNames: [{
    name: 'product-list-v2',
    url: `/${LIST_PAGE_PATHS.join('|')}`,
  }, {
    name: 'picked-info-list',
    url: '/picked-info-products.html',
  }], 
  handleAction(data) { // 每次收集到数据触发, data为每个埋点数据
    if (data.activity_name === 'page_view') {
      if (data.page_name !== 'page_search' || !data.page_param?.result_content) return null 
      return {
        result_content: data.page_param?.result_content // 只储存搜索页的result_content字段
      }
    }
    return data
  },
  onTrigger({ collectData, routerAction }) {

    console.warn('detailsRecommend before', collectData, routerAction)
    const rt_ft_list_filter = [] //  筛选条件
    const rt_ft_list_price = [] //  价格区间
    const rt_f_list_sort = [] // 列表排序
    const rt_f_list_top_nav = [] // 图文导航信息
    const rt_f_list_top_nav_query = [] // 图文导航信息query
    const rt_f_search_identifier = [] // 搜索页搜索词信息
    const rt_f_list_top_goods = [] // 列表首歌商品
    const rt_f_list_category_id = [] // category_id 类目ID
    const rt_f_list_label_id = [] // label_id 标签云的标签id
    Object.keys(collectData).forEach(session_id => {
      const {
        click_filter = [],
        click_sort = [],
        click_list_top_navigation = [],
        expose_goods_list = [],
        click_category = [],
        click_goods_list_label = [],
        page_view = [],
        _routeData = {},
      } = collectData[session_id] || {}

      const _currRoute = _routeData?.to || {}
      // const _preRoute = _routeData?.from
      const _attribute_listFilter = click_filter?.filter(d => d?.activity_param?.attribute_list_old)
      const _price_rangeFilter = click_filter?.filter(d => d?.activity_param?.price_range)

      const _last_attribute_listFilter = _getLastItemByList(_attribute_listFilter)?.activity_param?.attribute_list_old
      const _last_price_rangeFilter = _getLastItemByList(_price_rangeFilter)?.activity_param?.price_range

      if (_last_attribute_listFilter) {
        rt_ft_list_filter.push(_getSessionItemByList([session_id, _last_attribute_listFilter]))
      }

      if (_last_price_rangeFilter) {
        rt_ft_list_price.push(_getSessionItemByList([session_id, _last_price_rangeFilter]))
      }

      if (click_sort?.length) {
        rt_f_list_sort.push(_getSessionItemByList([session_id, _getLastItemByList(click_sort)?.activity_param?.sort || '0']))
      }

      // 是否搜索结果页
      const _isSearchPage = _currRoute.path?.match(new RegExp('pdsearch/.+'))
      const searchPageView = page_view.filter(d => d?.result_content)
      if (_isSearchPage && _getLastItemByList(searchPageView)?.result_content) {
        rt_f_search_identifier.push(_getSessionItemByList([session_id, _getLastItemByList(searchPageView).result_content]))
      }

      // 图文导航信息
      const _last_click_list_top_navigation = _getLastItemByList(click_list_top_navigation)?.activity_param?.top_navigation
      if (_last_click_list_top_navigation) {
        rt_f_list_top_nav.push(_getSessionItemByList([session_id, _last_click_list_top_navigation]))
      }

      // 图文导航信息
      const _last_click_list_top_navigation_query = _getLastItemByList(click_list_top_navigation)?.activity_param?.top_navigation_query
      if (_last_click_list_top_navigation_query) {
        rt_f_list_top_nav_query.push(_getSessionItemByList([session_id, _last_click_list_top_navigation_query]))
      }

      // 首页的SBC、首页导航、首页信息流 要上报goodsId
      const _isFromValidHome = _currRoute.query?.fromPageType === 'category' // 首页的SBC、首页导航
      || _currRoute.query?.entranceType === 'sbc' // 来自首页的SBC
      || _currRoute.name === 'picked-info-list' // 来自首页的信息流
  
      if (_isFromValidHome) {
        const firstExporseGoodsId = expose_goods_list?.[0]?.activity_param?.goods_list?.split('`')?.[0] // 首个曝光的商品goodsid

        if (firstExporseGoodsId) rt_f_list_top_goods.push(_getSessionItemByList([session_id, firstExporseGoodsId]))
      }
 

      // category_id 类目ID
      if (click_category?.length) {
        rt_f_list_category_id.push(_getSessionItemByList([session_id, _getLastItemByList(click_category)?.activity_param?.category_id]))
      }

      // label_id 标签云的标签id
      if (click_goods_list_label?.length) {
        rt_f_list_label_id.push(_getSessionItemByList([session_id, _getLastItemByList(click_goods_list_label)?.activity_param?.label_id]))
      }

    })

    const result = {
      rec_ctx: {
        // 无结果传undefined
        rt_ft_list_filter: _getSessionItemByList(rt_ft_list_filter),
        rt_ft_list_price: _getSessionItemByList(rt_ft_list_price),
        rt_f_list_sort: _getSessionItemByList(rt_f_list_sort),
        rt_f_list_top_nav: _getSessionItemByList(rt_f_list_top_nav),
        rt_f_list_top_nav_query: _getSessionItemByList(rt_f_list_top_nav_query),
        rt_f_search_identifier: _getSessionItemByList(rt_f_search_identifier, '`'),
        rt_f_list_top_goods: _getSessionItemByList(rt_f_list_top_goods),
        rt_f_list_category_id: _getSessionItemByList(rt_f_list_category_id),
        rt_f_list_label_id: _getSessionItemByList(rt_f_list_label_id),
      }
    }

    console.warn('detailsRecommend result', rt_ft_list_filter, rt_f_list_sort, rt_f_list_top_nav, rt_f_search_identifier, rt_f_list_top_goods, result)

    return result
  }
}

export default config
