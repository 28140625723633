/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import { getCookie } from '@shein/common-function'

import UserKey from './UserKey'

import Country from './UserKeys/Country'
import PrimeDisableCountry from './UserKeys/PrimeDisableCountry'
import Language from './UserKeys/Language'
import Currency from './UserKeys/Currency'
import MemberId from './UserKeys/MemberId'
import UserEmail from './UserKeys/UserEmail'
import UserInfo from './UserKeys/UserInfo'
import Ugid from './UserKeys/Ugid'
import WebPush from './UserKeys/WebPush'


UserKey.classes = [
  Country,
  PrimeDisableCountry,
  Language,
  Currency,
  MemberId,
  UserEmail,
  UserInfo,
  Ugid,
  WebPush,

  // UserKey
]

class UserInfoManager {

  /**
   * 异步get调用
   * 没有则通过接口获取
   * 保证一定有值
   * @param {*} param0 
   * @returns 
   */
  static get({ key, options, actionType = '' }) {
    UserInfoManager.getActionLists.add(actionType)

    return UserKey.for({ key }).get(options)
  }

  /**
   * 同步get调用
   * 直接取缓存session cookie获取
   * 不保证一定有值
   * @param {*} param0 
   * @returns 
   */
  static getSync({ key, options, actionType = '' }) {
    UserInfoManager.getActionLists.add(actionType)

    return UserKey.for({ key }).getSync(options)
  }

  static set({ key, value, options = {}, actionType = '' }) {
    UserInfoManager.setActionLists.add(actionType)

    return UserKey.for({ key }).set(value, options)
  }

  /**
   * 是否登录
   */
  static isLogin() {
    return !!getCookie('memberId')
  }

  /**
   * 语言切换
   */
  static switchLanguge() {

  }

  /**
   * 清除对应 UserKey 的 localstorage / sessionstorage 缓存
   */
  static clearStorage({ key }) {
    UserKey.for({ key }).clearStorage()
  }

  /**
   * 清除所有 UserKey 的 localstorage / sessionstorage 缓存
   */
  static clearAllStorage() {
    ['Country', 'language', 'memberId', 'userinfo_email', 'pwa_user_email', 'PrimeDisableCountry', 'ugid'].forEach(key => {
      UserInfoManager.clearStorage({ key })
    })
  }
}

UserInfoManager.getActionLists = new Set()
UserInfoManager.setActionLists = new Set()

export default UserInfoManager
