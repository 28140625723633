import { getSessionStorage, setSessionStorage } from '@shein/common-function'
import { log } from './utils.js'


class CheckPageRefresh {
  constructor() {
    this.key = 'action_track_history_length'
    this.current = null
  }

  get() {
    return this.current  || Number(getSessionStorage(this.key) || 0)
  }

  isRefresh() {
    if (this.current === null) {
      this.current = this.get()
    }
    return history.length === this.current
  }

  set() {
    // 设置太早了  history.length 还没有更新 加一个定时器
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.current = history.length
      setSessionStorage({
        key: this.key,
        value: this.current
      })
    }, 500)
  }
}

class TrackingController {
  constructor({ debug }) {
    this.debug = debug || false
    // 收集的实例集合
    this.collectDataInstanceHandlers = []
    // 路由的实例集合
    this.routerActionInstanceHandlers = []
    this.checkPageRefresh = new CheckPageRefresh()
  }

  /**
   * @description: 注册
   * */
  registerHandler({ key, collectDataInstance, routerActionInstance }) {
    const collectDataKey = `action_track_${key}_collect`
    const routerActionKey = `action_track_${key}_router`

    // 检查是否是刷新页面，是就从sessionStorage中取值
    if (this.checkPageRefresh.isRefresh()) {
      const routerActionData = getSessionStorage(routerActionKey)
      if (!routerActionData || routerActionData.length > this.checkPageRefresh.get().length) {
        // 保存的超过了当前的历史记录长度，证明是打开新页面
      } else {
        const collectData = getSessionStorage(collectDataKey)
        collectDataInstance.setInitData(collectData)
        routerActionInstance.setInitData(routerActionData)
      }
    }

    collectDataInstance.registerHandler({
      watch: (data, value) => {
        setSessionStorage({
          key: collectDataKey,
          value: data
        })
        this.checkPageRefresh.set()
        if (this.debug) {
          log(`[${key}]收集数据发生变化`, data, value)
        }
      }
    })
    this.collectDataInstanceHandlers.push(collectDataInstance)
    routerActionInstance.registerHandler({
      onStartCallback: (data) => {
        this.debug && log(`[${key}]收集start`)
        collectDataInstance.startCollect(data)
      },
      onEndCallback: () => {
        this.debug && log(`[${key}]收集数据end，清空数据`)
        collectDataInstance.endCollect()
      },
      targetTriggerCallback: (data) => {
        collectDataInstance.startCollect(data)
      },
      watch: (data) => {
        setSessionStorage({
          key: routerActionKey,
          value: data
        })
        this.checkPageRefresh.set()
        if (this.debug) {
          log(`[${key}]路由行为发生变化`, data)
        }
      }
    })
    this.routerActionInstanceHandlers.push(routerActionInstance)

    if (this.debug) {
      log(`[${key}]注册成功,初始化数据`, { collectData: collectDataInstance.getData(), routerAction: routerActionInstance.getData() })
    }
  }
  
  /**
   * @description: 触发收集数据(埋点每次触发)
  */
  triggerCollectDataHandlers(data) {
    this.collectDataInstanceHandlers.forEach(handler => {
      handler.trigger(data)
    })
  }

  /**
   * @description: 触发路由行为
   * */
  triggerRouterActionHandlers(to, from) {
    this.checkPageRefresh.set()
    this.routerActionInstanceHandlers.forEach(handler => {
      handler.trigger(to, from)
    })
  }
}

export { TrackingController }
